
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReportsVSHESheNkpMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93n7FBlbdhsPMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editor8MSSgvT7PiMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/blog/editor.vue?macro=true";
import { default as indexYX36IBD0UAMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/index.vue?macro=true";
import { default as moderationKk8lvffDXUMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/moderation.vue?macro=true";
import { default as pendingBans0OmeqdkZaJMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/pendingBans.vue?macro=true";
import { default as profilesJSp3qPc5UBMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/profiles.vue?macro=true";
import { default as expensesawKyfFsXsDMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/timesheets/expenses.vue?macro=true";
import { default as index90u4dYK87pMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewhxmh9TXxeeMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingtKi7moQ6NpMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/translations/awaiting.vue?macro=true";
import { default as missinglRtmZ1UVX4Meta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/translations/missing.vue?macro=true";
import { default as userss24jqht8lpMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/users.vue?macro=true";
import { default as api8OhkcnbvgTMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/api.vue?macro=true";
import { default as _91slug_9369fVis33S8Meta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/blog/[slug].vue?macro=true";
import { default as indexst3MJFSRShMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93I0fmg0a0qxMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93p90bHlgtfXMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminsoubGk1V5iMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/census/admin.vue?macro=true";
import { default as indexMHhc7flwPOMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/census/index.vue?macro=true";
import { default as contactNgOdTxP1N7Meta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/contact.vue?macro=true";
import { default as designtzpvB1CAjxMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/design.vue?macro=true";
import { default as englishOKq3Ne8VS7Meta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/english.vue?macro=true";
import { default as faqCbqZH7UKXvMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/faq.vue?macro=true";
import { default as inclusivebRCYuokyd3Meta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/inclusive.vue?macro=true";
import { default as index_45homexFgFULp9rsMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/index-home.vue?macro=true";
import { default as index_45localeZNV8K91tBdMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/index-locale.vue?macro=true";
import { default as licensewC6D3NVBQMMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/license.vue?macro=true";
import { default as academicOA741z4SHbMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/links/academic.vue?macro=true";
import { default as index50WuDVW7anMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/links/index.vue?macro=true";
import { default as media9dYJXT3iYRMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/links/media.vue?macro=true";
import { default as translinguistics2rrAe6fi1LMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/links/translinguistics.vue?macro=true";
import { default as zineaIl4nfMiFEMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/links/zine.vue?macro=true";
import { default as namesUVPE48mtI5Meta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/names.vue?macro=true";
import { default as indexIa47eelaTTMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/nouns/index.vue?macro=true";
import { default as templatesHhgA3lxaxDMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/nouns/templates.vue?macro=true";
import { default as peopleradJv8uxw2Meta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/people.vue?macro=true";
import { default as privacygodIecSbYKMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/privacy.vue?macro=true";
import { default as _91username_937EQAsSWI4OMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93Rd7RloV1fjMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/profile/card-[username].vue?macro=true";
import { default as editorMMki46KCgFMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/profile/editor.vue?macro=true";
import { default as anyHAPKbTj6U9Meta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/any.vue?macro=true";
import { default as askj5FKR5v7UeMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/ask.vue?macro=true";
import { default as avoidingQSS8oka7rKMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/avoiding.vue?macro=true";
import { default as indexfylguC0IJoMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/index.vue?macro=true";
import { default as mirrorRUkkGtrhuzMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/mirror.vue?macro=true";
import { default as pronouncXwavwsO9qMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesYjpgFKhvuzMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/sources.vue?macro=true";
import { default as teamD6Du4RTAqvMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/team.vue?macro=true";
import { default as terminologyg2IFkT9Aw2Meta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/terminology.vue?macro=true";
import { default as termsGIiwdh4yWjMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/terms.vue?macro=true";
import { default as user17s6GRMceJMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/user.vue?macro=true";
import { default as workshopspwQGoIWrnkMeta } from "/home/admin/www/pronomejo.net/release/20250118115853/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsVSHESheNkpMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBans0OmeqdkZaJMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: api8OhkcnbvgTMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_9369fVis33S8Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexst3MJFSRShMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93I0fmg0a0qxMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93p90bHlgtfXMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminsoubGk1V5iMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexMHhc7flwPOMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactNgOdTxP1N7Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishOKq3Ne8VS7Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqCbqZH7UKXvMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivebRCYuokyd3Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45homexFgFULp9rsMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localeZNV8K91tBdMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/index-locale.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicOA741z4SHbMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: index50WuDVW7anMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: media9dYJXT3iYRMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguistics2rrAe6fi1LMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineaIl4nfMiFEMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesUVPE48mtI5Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexIa47eelaTTMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesHhgA3lxaxDMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleradJv8uxw2Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacygodIecSbYKMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_937EQAsSWI4OMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93Rd7RloV1fjMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorMMki46KCgFMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyHAPKbTj6U9Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askj5FKR5v7UeMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingQSS8oka7rKMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexfylguC0IJoMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorRUkkGtrhuzMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronouncXwavwsO9qMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesYjpgFKhvuzMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamD6Du4RTAqvMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyg2IFkT9Aw2Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsGIiwdh4yWjMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: user17s6GRMceJMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopspwQGoIWrnkMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20250118115853/pages/workshops.vue")
  }
]